import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { formatPrice } from '../../utils/helpers';
import { clearCart, getAllCarts, setPaymentStatusIdle, getAsyncStatusPayment } from '../../store/cartSlice';
import { shopping_cart } from '../../utils/images';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import './PaymentPage.scss';

const PaymentPage = () => {
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch()
    const carts = useSelector(getAllCarts);
    const { itemsCount, totalAmount, virtualOrQris, checkStatusPayload } = useSelector((state) => state.cart);
    const payMethod = virtualOrQris.paymentMethodResponse.paymentMethodCode;

    const dummyPaymentMethod = [
        "Login Internet banking",
        "Choose Bayar",
        "Choose Multi payment",
        "Input i-Pay sebagai Penyedia jasa",
        "Input Nomor Virtual Account , misal 70017XXXXXXXXXXX",
        "Choose Lanjut",
        "Input OTP and PIN",
        "Choose OK",
        "Proof of Payment shown",
        "Finish",
    ]

    if (carts.length === 0) {
        return (
            <div className='container my-5'>
                <div className='empty-cart flex justify-center align-center flex-column font-manrope'>
                    <img src={shopping_cart} alt="" />
                    <span className='fw-6 fs-15 text-gray'>Thank you for Shopping!<br />Detail belanja akan dikirimkan via email.</span>
                    <Link to="/" className='shopping-btn bg-orange text-white fw-5'>Go shopping Now</Link>
                </div>
            </div>
        )
    }

    const statusCheck = async () => {
        if (Object.keys(checkStatusPayload).length !== 0) {
            if (checkStatusPayload.process.status === "SUCCESS") {
                dispatch(setPaymentStatusIdle())
                dispatch(clearCart())
                return
            }
        }
        setOpen(true)
        dispatch(getAsyncStatusPayment(virtualOrQris.paymentMethodResponse.idPayin))
    }

    const handleClose = () => {
        setOpen(false);
    }

    return (
        <div className="payment-page">
            <h2>{payMethod === "QRISPAY" ? "Qris" : "Virtual Account"}</h2>
            <div className="payment-methods">
                <div className="payment-item">
                    <h3>Metode Pembayaran</h3>
                    {payMethod === "QRISPAY" ? <img src={virtualOrQris.paymentMethodResponse.qrCode} /> : <h3>{virtualOrQris.paymentMethodResponse.account.accountNumber}</h3>}
                    <p className='text-center'>{payMethod === "QRISPAY" ? "QR Code" : "Nomor Virtual Account"}</p>
                    <br />
                    {payMethod === "VAPAY" ?? <p>Bank: {virtualOrQris.paymentMethodResponse.account.bankName} Virtual Account</p>}
                    {payMethod === "VAPAY" ? (
                        <>
                            <p>Payment Method via Internet Banking:</p>
                            <ol>
                                {dummyPaymentMethod.map((step, index) => (
                                    <li key={index}>{step}</li>
                                ))}
                            </ol>
                        </>
                    ) : null}
                    <p>Notes: <br />Jangan tutup halaman ini sebelum menyelesaikan pembayaran!<br /><br />Mohon klik cek status jika sudah membayar untuk proses selanjutnya.</p>
                </div>
            </div>
            <div className='total-txt flex align-center justify-center'>
                <div className='font-manrope fw-5'>Total ({itemsCount}) items: </div>
                <span className='text-orange fs-22 mx-2 fw-6'>{formatPrice(totalAmount)}</span>
            </div>

            <div className='justify-center flex align-center my-1'>
                <button type="button" className='checkout-btn text-white bg-orange fs-17 py-1 px-2 justify-end' onClick={statusCheck}>Cek Status</button>
            </div>

            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert
                    onClose={handleClose}
                    severity="warning"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    Mohon lakukan pembayaran!
                </Alert>
            </Snackbar>
        </div>
    );
};

export default PaymentPage;
