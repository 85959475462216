export const products = ["bicycle", "bike accesories", "shoes"];
export const merchantCode = "LFI22-QU6IJ0VWZW";
export const merchantSecret = "xHseNUHPhIMJYyJm9eaO54JtN9fV1mLU9bCWpBMcMbze4ZTkO9";

export const  paymentMandiriMethods = [
    {
        name: 'Payment Method via ATM',
        steps: [
            "Input kartu ATM dan PIN Anda",
            "Choose Bayar / Beli",
            "Choose Lainnya",
            "Choose Multi Payment",
            "Input 70017, as institution code",
            "Input Nomor Virtual Account, e.g., 70017XXXXXXXXXXX",
            "Choose Benar",
            "Choose Iya",
            "Finish"
        ]
    },
    {
        name: 'Payment Method via Mobile Banking',
        steps: [
            "Login Mobile Banking",
            "Choose Bayar",
            "Input i-Pay sebagai Penyedia jasa",
            "Input Nomor Virtual Account, example 70017XXXXXXXXXXX",
            "Choose Lanjut",
            "Input OTP and PIN",
            "Choose OK",
            "Proof of Payment shown",
            "Finish"
        ]
    },
    {
        name: 'Payment Method via Internet Banking',
        steps: [
            "Login Internet banking",
            "Choose Bayar",
            "Choose Multi payment",
            "Input i-Pay sebagai Penyedia jasa",
            "Input Nomor Virtual Account, misal 70017XXXXXXXXXXX",
            "Choose Lanjut",
            "Input OTP and PIN",
            "Choose OK",
            "Proof of Payment shown",
            "Finish"
        ]
    },
    {
        name: 'Payment Guide for Other bank',
        steps: [
            "Input kartu ATM dan PIN Anda",
            "Choose Bayar / Beli",
            "Choose Lainnya",
            "Choose Transfer Bank",
            "Choose Bank Mandiri (008)",
            "Input 88017,followed by virtual account, Example 881714501000009",
            "Input Payment Amount",
            "Finished Payment"
        ]
    }
];