export const formatPrice = (price) => {
    const formattedPrice = new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: "IDR"
    }).format(price);

    // Removed .00 from price suffix
    const value = formattedPrice.replace(/\,00$/, '');
    return value;
}

export const generateRandomInt = () => {
    return Math.floor(1000 + Math.random() * 9000);
  }
  
