import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PAYMENT_URL } from "../utils/apiURL";
import { generateRandomInt } from "../utils/helpers";
import { merchantCode, merchantSecret } from "../utils/constants";
import { STATUS } from "../utils/status";

const fetchFromLocalStorage = () => {
    let cart = localStorage.getItem('cart');
    if (cart) {
        return JSON.parse(localStorage.getItem('cart'));
    } else {
        return [];
    }
}

const storeInLocalStorage = (data) => {
    localStorage.setItem('cart', JSON.stringify(data));
}

const initialState = {
    carts: fetchFromLocalStorage(),
    itemsCount: 0,
    totalAmount: 0,
    isCartMessageOn: false,
    paymentStatus: STATUS.IDLE,
    checkStatus: STATUS.IDLE,
    checkStatusPayload: {},
    virtualOrQris: {}
};

const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        addToCart: (state, action) => {
            const isItemInCart = state.carts.find(item => item.id === action.payload.id);

            if (isItemInCart) {
                const tempCart = state.carts.map(item => {
                    if (item.id === action.payload.id) {
                        let tempQty = item.quantity + action.payload.quantity;
                        let tempTotalPrice = tempQty * item.price;

                        return {
                            ...item, quantity: tempQty, totalPrice: tempTotalPrice
                        }
                    } else {
                        return item;
                    }
                });

                state.carts = tempCart;
                storeInLocalStorage(state.carts);
            } else {
                state.carts.push(action.payload);
                storeInLocalStorage(state.carts);
            }
        },

        removeFromCart: (state, action) => {
            const tempCart = state.carts.filter(item => item.id !== action.payload);
            state.carts = tempCart;
            storeInLocalStorage(state.carts);
        },

        clearCart: (state) => {
            state.carts = [];
            state.checkStatusPayload = {};
            state.checkStatus = STATUS.IDLE;
            storeInLocalStorage(state.carts);
        },

        getCartTotal: (state) => {
            state.totalAmount = state.carts.reduce((cartTotal, cartItem) => {
                return cartTotal += cartItem.totalPrice
            }, 0);

            state.itemsCount = state.carts.length;
        },

        toggleCartQty: (state, action) => {
            const tempCart = state.carts.map(item => {
                if (item.id === action.payload.id) {
                    let tempQty = item.quantity;
                    let tempTotalPrice = item.totalPrice;

                    if (action.payload.type === "INC") {
                        tempQty++;
                        if (tempQty === item.stock) tempQty = item.stock;
                        tempTotalPrice = tempQty * item.discountedPrice;
                    }

                    if (action.payload.type === "DEC") {
                        tempQty--;
                        if (tempQty < 1) tempQty = 1;
                        tempTotalPrice = tempQty * item.discountedPrice;
                    }

                    return { ...item, quantity: tempQty, totalPrice: tempTotalPrice };
                } else {
                    return item;
                }
            });

            state.carts = tempCart;
            storeInLocalStorage(state.carts);
        },

        setCartMessageOn: (state) => {
            state.isCartMessageOn = true;
        },

        setCartMessageOff: (state) => {
            state.isCartMessageOn = false;
        },

        setPaymentStatusIdle: (state) => {
            state.paymentStatus = STATUS.IDLE
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAsyncPayment.pending, (state, action) => {
                state.paymentStatus = STATUS.LOADING
            })

            .addCase(fetchAsyncPayment.fulfilled, (state, action) => {
                state.virtualOrQris = action.payload
                state.paymentStatus = STATUS.SUCCEEDED;
            })

            .addCase(fetchAsyncPayment.rejected, (state, action) => {
                state.paymentStatus = STATUS.FAILED
            })

            .addCase(getAsyncStatusPayment.pending, (state, action) => {
                state.checkStatus = STATUS.LOADING
            })

            .addCase(getAsyncStatusPayment.fulfilled, (state, action) => {
                state.checkStatusPayload = action.payload
                state.checkStatus = STATUS.SUCCEEDED;
            })

            .addCase(getAsyncStatusPayment.rejected, (state, action) => {
                state.checkStatus = STATUS.FAILED
            })
    }
});

// request payment
export const fetchAsyncPayment = createAsyncThunk(
    'payment/fetch',
    async (paymentRequest) => {
            const data = {
                merchantCode: merchantCode,
                secret: merchantSecret,
                validitySecs: 3600,
                operations: [
                    "POST /.*",
                    "GET /.*"
                ]
            }

            const responseToken = await fetch(`${PAYMENT_URL}/auth-tokens`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-API-Version': '2',
                },
                body: JSON.stringify(data)
            });

            const token = await responseToken.json()

            const id = generateRandomInt()

            const response = await fetch(`${PAYMENT_URL}/payins/${id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.token}`,
                    'X-API-Version': '2'
                },
                body: JSON.stringify(paymentRequest),
            });

            const responseData = await response.json();

            return responseData;
    }
);

// get status
export const getAsyncStatusPayment = createAsyncThunk(
    'payment/status',
    async (id) => {
        const data = {
            merchantCode: merchantCode,
            secret: merchantSecret,
            validitySecs: 3600,
            operations: [
                "POST /.*",
                "GET /.*"
            ]
        }

        const responseToken = await fetch(`${PAYMENT_URL}/auth-tokens`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-API-Version': '2',
            },
            body: JSON.stringify(data)
        });

        const token = await responseToken.json()

        const response = await fetch(`${PAYMENT_URL}/payins/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token.token}`,
                    'X-API-Version': '2'
            }
        })

        const responseData = await response.json();

        return responseData;
    }
)

export const { addToCart, setCartMessageOff, setCartMessageOn, getCartTotal, toggleCartQty, clearCart, removeFromCart, setPaymentStatusIdle } = cartSlice.actions;
export const getAllCarts = (state) => state.cart.carts;
export const getCartItemsCount = (state) => state.cart.itemsCount;
export const getCartMessageStatus = (state) => state.cart.isCartMessageOn;
export const getPaymentStatus = (state) => state.cart.paymentStatus;

export default cartSlice.reducer;