import { Link } from 'react-router-dom';
import { shopping_cart } from '../../utils/images';

const RegisterLoginPage = () => {
    return (
        <div className='container my-5'>
                <div className='empty-cart flex justify-center align-center flex-column font-manrope'>
                    <img src={shopping_cart} alt="" />
                    <span className='fw-6 fs-15 text-gray'>Mohon maaf halaman ini masih dalam pengembangan, tapi kamu bisa langsung berbelanja tanpa harus Register/Login, Terimakasih!</span>
                    <Link to="/" className='shopping-btn bg-orange text-white fw-5'>Go shopping Now</Link>
                </div>
            </div>
    )
}

export default RegisterLoginPage;
