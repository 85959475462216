import VogelImg from "../images/flash-1.png"
import PacificBike from "../images/flash-2.png"
import BromptonImg from "../images/flash-3.jpg"
import Wake from "../images/flash-4.jpg"
import BikeHelmetImg from "../images/shops-1.png"
import BlueBikeHelmetImg from "../images/shops-2.png"
import Poc from "../images/shops-3.png"
import TGRHelmet from "../images/TGR_Bike_Helmet-removebg-preview.png"
import BromptonCLineImg from "../images/brompton_c_line_explore_black_edition-removebg-preview.png"
import POCBikeHelmetImg from "../images/bike_helmet-removebg-preview.png"
import BrooksShoesImg from "../images/shops-4.png"
import DCShoes from "../images/shops-5.png"
import BrooksHyperRunElite from "../images/shops-6.png"
import BrooksPwrRun from "../images/shops-7.png"
import NikeRun from "../images/discount-7.png"
import TriSixty from "../images/brompton_3_sixty.jpeg"

export const dummyData = [{
    "id": 1,
    "title": "Blue Vogel Bike",
    "description": "Vogel Bike: The Ultimate Ride Experience",
    "price": 2500000,
    "discountPercentage": 20,
    "rating": 4.69,
    "stock": 2,
    "brand": "Vogel",
    "category": "bicycle",
    "thumbnail": VogelImg,
    "images": [VogelImg]
},
{
    "id": 2,
    "title": "Pacific Bike R21",
    "description": "Pacific Bike R21: Embark on Your Cycling Odyssey",
    "price": 800000,
    "discountPercentage": 12.96,
    "rating": 4.00,
    "stock": 1,
    "brand": "Pacific",
    "category": "bicycle",
    "thumbnail": PacificBike,
    "images": [PacificBike]
},
{
    "id": 3,
    "title": "Brompton Explore M6E 2020",
    "description": "Brompton Explore M6E 2020: Unleash Your Urban Cycling Adventure",
    "price": 100000000,
    "discountPercentage": 12.96,
    "rating": 4.00,
    "stock": 1,
    "brand": "Brompton",
    "category": "bicycle",
    "thumbnail": BromptonImg,
    "images": [BromptonImg]
},
{
    "id": 4,
    "title": "Wake Mtb Bike Handlebar Stem Bicycle Stem 31.8mm",
    "description": "Cycling Bicycle Parts Bicicleta Bike Handlebar Stem Aluminum Alloy",
    "price": 107000,
    "discountPercentage": 5,
    "rating": 4.5,
    "stock": 2,
    "brand": "Wake",
    "category": "bike accesories",
    "thumbnail": Wake,
    "images": [Wake]
},
{
    "id": 5,
    "title": "Bell Axle Bike Helmet",
    "description": "Bell Axle Bike Helmet: Gear Up for Safe and Stylish Rides",
    "price": 376000,
    "discountPercentage": 5,
    "rating": 4.7,
    "stock": 1,
    "brand": "Axle",
    "category": "bike accesories",
    "thumbnail": BikeHelmetImg,
    "images": [BikeHelmetImg]
},
{
    "id": 6,
    "title": "Giro Scamp MIPS",
    "description": "Giro Scamp MIPS Bike Helmet: Safe and Stylish Protection for Young Riders",
    "price": 360000,
    "discountPercentage": 5,
    "rating": 4.2,
    "stock": 1,
    "brand": "Giro",
    "category": "bike accesories",
    "thumbnail": BlueBikeHelmetImg,
    "images": [BlueBikeHelmetImg]
},
{
    "id": 7,
    "title": "POC Bike Helmet",
    "description": "POC Bike Helmet: Cutting-edge Protection for Every Ride",
    "price": 350000,
    "discountPercentage": 5,
    "rating": 4.5,
    "stock": 2,
    "brand": "POC",
    "category": "bike accesories",
    "thumbnail": Poc,
    "images": [Poc]
},
{
    "id": 8,
    "title": "Brompton C Line Explore Black Edition",
    "description": "Brompton C Line Explore Black Edition: Your Companion for Urban Exploration",
    "price": 36800000,
    "discountPercentage": 5,
    "rating": 4.5,
    "stock": 2,
    "brand": "Brompton",
    "category": "bicycle",
    "thumbnail": BromptonCLineImg,
    "images": [BromptonCLineImg]
},
{
    "id": 9,
    "title": "POC Bike Helmet",
    "description": "POC Bike Helmet: Cutting-edge Protection for Every Ride",
    "price": 370000,
    "discountPercentage": 5,
    "rating": 4.5,
    "stock": 2,
    "brand": "POC",
    "category": "bike accesories",
    "thumbnail": POCBikeHelmetImg,
    "images": [POCBikeHelmetImg]
},
{
    "id": 10,
    "title": "Brooks Z21",
    "description": "Brooks Z21 Shoes: Step into Comfort and Performance",
    "price": 1200000,
    "discountPercentage": 5,
    "rating": 4.5,
    "stock": 2,
    "brand": "Brooks",
    "category": "shoes",
    "thumbnail": BrooksShoesImg,
    "images": [BrooksShoesImg]
},
{
    "id": 11,
    "title": "DC Shoes",
    "description": "DC Shoes: Unleash Your Style and Performance",
    "price": 2200000,
    "discountPercentage": 5,
    "rating": 4.5,
    "stock": 2,
    "brand": "DC",
    "category": "shoes",
    "thumbnail": DCShoes,
    "images": [DCShoes]
},
{
    "id": 12,
    "title": "Brooks Hyperrun Elite",
    "description": "Brooks Hyperrun Elite: Unleash Your Maximum Performance",
    "price": 1800000,
    "discountPercentage": 5,
    "rating": 4.5,
    "stock": 2,
    "brand": "Brooks",
    "category": "shoes",
    "thumbnail": BrooksHyperRunElite,
    "images": [BrooksHyperRunElite]
},
{
    "id": 13,
    "title": "Brooks Power Run",
    "description": "Brooks Power Run: Boost Your Running Potential",
    "price": 1500000,
    "discountPercentage": 5,
    "rating": 4.5,
    "stock": 2,
    "brand": "Brooks",
    "category": "shoes",
    "thumbnail": BrooksPwrRun,
    "images": [BrooksPwrRun]
},
{
    "id": 14,
    "title": "Nike Run",
    "description": "Nike Run: Unleash Your Potential on the Track",
    "price": 1100000,
    "discountPercentage": 5,
    "rating": 4.5,
    "stock": 2,
    "brand": "Nike",
    "category": "shoes",
    "thumbnail": NikeRun,
    "images": [NikeRun]
},
{
    "id": 15,
    "title": "3sixty",
    "description": "3sixty: The Folding Bike Experience Inspired by Brompton",
    "price": 9999500,
    "discountPercentage": 5,
    "rating": 4.5,
    "stock": 1,
    "brand": "3sixty",
    "category": "bicycle",
    "thumbnail": TriSixty,
    "images": [TriSixty]
}]