import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { formatPrice } from '../../utils/helpers';
import { STATUS } from '../../utils/status';
import Loader from '../../components/Loader/Loader';
import { fetchAsyncPayment } from '../../store/cartSlice';
import { getPaymentStatus } from '../../store/cartSlice'
import './Checkout.scss';

const Checkout = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const paymentStatus = useSelector(getPaymentStatus)

    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        address: '',
        postalCode: '',
        paymentMethod: '',
    });

    const { itemsCount, totalAmount } = useSelector((state) => state.cart);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        let payMethod = "VAPAY"
        if (formData.paymentMethod === "qris") {
            payMethod = "QRISPAY";
        }

        const payload = {
            paymentRequested: {
                money: {
                    amount: parseInt(totalAmount),
                    currencyCode: "IDR"
                }
            },
            paymentMethod: {
                paymentMethodCode: payMethod,
                account: {
                    accountName: formData.fullName,
                },
                paymentOperatorCode: formData.paymentMethod,
                emailAddress: formData.email,
            },
            callbackUrl: "https://example.com",
            returnUrl: "https://example.com"
        }

        setFormData({
            fullName: '',
            email: '',
            address: '',
            postalCode: '',
            paymentMethod: '',  
        })

        dispatch(fetchAsyncPayment(payload))
    }

    useEffect(() => {
        if (paymentStatus === STATUS.SUCCEEDED) {
            navigate('/payment-page')
        }
    }, [paymentStatus, navigate]);


    return (
        <div className="checkout-page">
            <h2>Checkout</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="fullName">Nama Lengkap:</label>
                    <input type="text" id="fullName" name="fullName" value={formData.fullName} onChange={handleChange} required />
                </div>
                <div className="form-group">
                    <label htmlFor="email">Alamat Email:</label>
                    <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />
                </div>
                <div className="form-group">
                    <label htmlFor="address">Alamat Pengiriman:</label>
                    <textarea id="address" name="address" value={formData.address} onChange={handleChange} rows="4" required />
                </div>
                <div className="form-group">
                    <label htmlFor="postalCode">Kode Pos:</label>
                    <input type="text" id="postalCode" name="postalCode" value={formData.postalCode} onChange={handleChange} required />
                </div>
                <div className="form-group">
                    <label>Metode Pembayaran:</label>
                    <div className="payment-method">
                        <label>
                            <input type="radio" name="paymentMethod" value="qris" onChange={handleChange} required />
                            QRIS
                        </label>
                        <label>
                            <input type="radio" name="paymentMethod" value="IDR_014" onChange={handleChange} required />
                            BCA Virtual Account (UNDER DEVELOPMENT)
                        </label>
                        <label>
                            <input type="radio" name="paymentMethod" value="IDR_008" onChange={handleChange} required />
                            Mandiri Virtual Account
                        </label>
                    </div>

                    <div className='total-txt flex align-center justify-end'>
                        <div className='font-manrope fw-5'>Total ({itemsCount}) items: </div>
                        <span className='text-orange fs-22 mx-2 fw-6'>{formatPrice(totalAmount)}</span>
                    </div>
                </div>

                {paymentStatus === STATUS.LOADING ? <Loader /> : <button type="submit">Submit</button>}
            </form>
        </div>
    );
};

export default Checkout;
