import React, { useEffect } from 'react';
import "./HomePage.scss";
import HeaderSlider from "../../components/Slider/HeaderSlider";
import { useSelector, useDispatch } from 'react-redux';
import ProductList from "../../components/ProductList/ProductList";
import { fetchAsyncProducts, getAllProducts, getAllProductsStatus } from '../../store/productSlice';
import Loader from "../../components/Loader/Loader";
import { STATUS } from '../../utils/status';
import { products as ribikeProduct } from '../../utils/constants';
import { dummyData } from '../../assets/DummyData/DummyData';

const HomePage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAsyncProducts(50));
  }, []);

  const productStatus = useSelector(getAllProductsStatus);

  // randomizing the products in the list
  const tempProducts = [];
  if (dummyData.length > 0) {
    for (let i in dummyData) {
      let randomIndex = Math.floor(Math.random() * dummyData.length);

      while (tempProducts.includes(dummyData[randomIndex])) {
        randomIndex = Math.floor(Math.random() * dummyData.length);
      }
      tempProducts[i] = dummyData[randomIndex];
    }
  }

  let catProductsOne = dummyData.filter(product => product.category === ribikeProduct[0]);
  let catProductsTwo = dummyData.filter(product => product.category === ribikeProduct[1]);
  let catProductsThree = dummyData.filter(product => product.category === ribikeProduct[2]);

  return (
    <main>
      <div className='slider-wrapper'>
        <HeaderSlider />
      </div>
      <div className='main-content bg-whitesmoke'>
        <div className='container'>
          <div className='categories py-5'>
            <div className='categories-item'>
              <div className='title-md'>
                <h3>Produk Kami</h3>
              </div>
              {productStatus === STATUS.LOADING ? <Loader /> : <ProductList products={tempProducts} />}
            </div>

            <div className='categories-item'>
              <div className='title-md'>
                <h3>{ribikeProduct[0]}</h3>
              </div>
              {productStatus === STATUS.LOADING ? <Loader /> : <ProductList products={catProductsOne} />}
            </div>

            <div className='categories-item'>
              <div className='title-md'>
                <h3>{ribikeProduct[1]}</h3>
              </div>
              {productStatus === STATUS.LOADING ? <Loader /> : <ProductList products={catProductsTwo} />}
            </div>

            <div className='categories-item'>
              <div className='title-md'>
                <h3>{ribikeProduct[2]}</h3>
              </div>
              {productStatus === STATUS.LOADING ? <Loader /> : <ProductList products={catProductsThree} />}
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default HomePage