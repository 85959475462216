import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../utils/apiURL";
import { STATUS } from "../utils/status";
import { dummyData } from "../assets/DummyData/DummyData";

const initialState = {
    searchProducts: [],
    searchProductsStatus: STATUS.IDLE
}

const searchSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {
        clearSearch: (state, action) => {
            state.searchProducts = [];
        },
        getProductSearch: (state, action) => {
            const searchProduct = dummyData.filter(
                product => product.title.toLowerCase().includes(action.payload.toLowerCase()) ||
                    product.category.toLowerCase().includes(action.payload.toLowerCase())
            );

            if (action.payload === "") {
                state.searchProducts = []
            }

            state.searchProducts = searchProduct
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAsyncSearchProduct.pending, (state, action) => {
                state.searchProductsStatus = STATUS.LOADING;
            })

            .addCase(fetchAsyncSearchProduct.fulfilled, (state, action) => {
                state.searchProducts = action.payload;
                state.searchProductsStatus = STATUS.SUCCEEDED;
            })

            .addCase(fetchAsyncSearchProduct.rejected, (state, action) => {
                state.searchProductsStatus = STATUS.FAILED;
            })
    }
})

export const fetchAsyncSearchProduct = createAsyncThunk('product-search/fetch', async (searchTerm) => {
    const response = await fetch(`${BASE_URL}products/search?q=${searchTerm}`);
    const data = await response.json();
    return data.products;
});

export const { setSearchTerm, clearSearch, getProductSearch } = searchSlice.actions;
export const getSearchProducts = (state) => state.search.searchProducts;
export const getSearchProductsStatus = (state) => state.search.searchProductsStatus;
export default searchSlice.reducer;