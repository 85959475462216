import React from 'react';
import './AboutUs.scss';
import ribikeImage from '../../assets/images/RIBIKE.png';

const AboutUs = () => {
    const email = 'support@ribike.id';

    return (
        <div className="about-us-container">
            <h1>About Us</h1>
            <div className="content">
                <div className="description">
                    <p>Ribike.id adalah toko sepeda yang berdiri sejak tahun 2022 dibawah naungan PT Fortune Global Finance. Kami berkomitmen untuk menyediakan sepeda berkualitas dengan harga terjangkau kepada masyarakat.</p>
                    <p>Memang kami masih baru, tetapi kami selalu berusaha menjadi toko sepeda terpercaya di Indonesia. Kami menyediakan berbagai jenis sepeda, perlengkapan, dan aksesoris sepeda untuk memenuhi kebutuhan para pelanggan.</p>
                    <p>Kamu bisa menghubungi kami di <a href={`mailto:${email}`}>{email}</a> jika ada yang ingin ditanyakan lebih lanjut!</p>
                </div>
                <div className="image-container">
                    <img src={ribikeImage} alt="Ribike" />
                </div>
            </div>
        </div>
    );
};

export default AboutUs;
